<template>
  <div class="body container-fluid">
    <div class="row justify-content-center">
      <div class="col-10 col-lg-6">
        <div class="card p-5">
          <div class="row">
            <div class="col-12 text-center">
              <img src="https://catsitterindonesia.com/image/logoorange.75f6438e.png" />

              <p>Our website is under construction</p>
              <a
                class="btn btn-lg"
                href="https://api.whatsapp.com/send/?phone=%206287730903400&text=Halo%2C+Saya+tertarik+untuk+memesan+jasa+cat+sitter&app_absent=0"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logoorange from '@/assets/logoorange.png'

export default {
  name: 'MaintenancePage',
  data() {
    return {
      logoorange,
    }
  },
  mounted() {},
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body {
  background: url('https://catsitterindonesia.com/image/IMG_1391-1727757064.png'), #f8eee2;
  background-position-y: bottom;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: 60vw 60vw;
  height: 100vh;
}
.row.justify-content-center {
  padding-top: 20vh;
}

img {
  max-width: 20vw;
}

.card {
  border-radius: 18px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.6);
}

.card p {
  font-size: 1.5vw;
  font-weight: bold;
  color: #999;
  line-height: 1.2;
  margin-top: 2vw;
}

.card .btn {
  background: #ff5a2a;
  color: #fff;
  margin-top: 6vw;
}

.card .btn:active {
  color: #fff;
}

.card .btn:hover {
  background: #ff5a2a;
  border-color: #ff5a2a;
  box-shadow: 0 8px 25px -8px #ff5a2a;
}

@media screen and (max-width: 992px) {
  .body {
    background-size: 100vw 100vw;
  }

  .row.justify-content-center {
    padding-top: 10vh;
  }

  img {
    max-width: 40vw;
  }

  .card p {
    font-size: 5vw;
    margin-top: 10vw;
  }

  .card .btn {
    margin-top: 20vw;
  }
}
</style>
