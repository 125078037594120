<template>
  <div>
    <Loader v-if="isLoading" color="#000" />
    <div class="top">
      <div class="row">
        <div class="col-3">
          <p class="title">
            Sitters
          </p>
        </div>
        <div class="col-3">
          <b-form-group label="Search Name">
            <b-form-input
              v-model="formSearch.name"
              type="text"
              @input="handleFilterData()"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-3">
          <b-form-group label="Search Service">
            <b-form-select
              v-model="formSearch.service"
              :options="serviceOptions"
              @change="handleFilterData()"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-3 text-end">
          <b-button @click="handleOpenModalCreate" class="new ms-auto">
            New Sitter
          </b-button>
        </div>
      </div>
    </div>
    <div class="content" v-if="showSitters[0]">
      <div v-for="(item, index) in showSitters" :key="item.id" class="row">
        <div class="col-12">
          <p class="title">
            {{ index + 1
            }}{{
              index === 0
                ? 'st'
                : index === 1
                ? 'nd'
                : index === 2
                ? 'rd'
                : 'th'
            }}
            Sitter
          </p>
        </div>

        <div class="col-6 mb-3 align-items-end">
          <b-form-group label="Name">
            <b-form-input
              v-model="showSitters[index].name"
              type="text"
              :disabled="disabledForm[index].name"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-4 mb-3">
          <b-form-group label="Profile Picture">
            <b-form-input
              v-model="showSitters[index].img"
              type="text"
              :disabled="disabledForm[index].img"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-2 mb-3 text-end">
          <a :href="showSitters[index].img" target="_blank">
            <img
              :src="showSitters[index].img"
              alt="illustration"
              width="80"
              height="80"
            />
          </a>
        </div>

        <div class="col-4 mb-3 align-items-end">
          <b-form-group label="Instagram">
            <b-form-input
              v-model="showSitters[index].ig"
              type="text"
              :disabled="disabledForm[index].ig"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-2 mb-3 align-items-end">
          <b-form-group label="City">
            <b-form-select
              v-model="showSitters[index].city"
              :options="cityOptions"
              :disabled="disabledForm[index].city"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="col-1 mb-3 align-items-end">
          <b-form-group label="Rating">
            <b-form-select
              v-model="showSitters[index].rating"
              :options="ratingOptions"
              :disabled="disabledForm[index].rating"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="col-2 mb-3 align-items-end">
          <b-form-group label="Service">
            <b-form-select
              v-model="showSitters[index].service"
              :options="
                serviceOptions.filter((item) => item !== 'Show All Service')
              "
              :disabled="disabledForm[index].service"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="col-2 mb-3 align-items-end">
          <b-form-group label="Level">
            <b-form-select
              v-model="showSitters[index].level"
              :options="levelOptions"
              :disabled="disabledForm[index].level"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="col-1 mb-3 align-items-end">
          <b-form-group label="Year">
            <b-form-input
              v-model="showSitters[index].year"
              type="number"
              :disabled="disabledForm[index].year"
              min="0"
              max="50"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-6 mb-3 align-items-end">
          <b-form-group label="Introduction">
            <Editor
              api-key="seu906tl28cai7l7fvsn6bsi51ybqeof5m94ed3scxdflh4e"
              v-model="showSitters[index].intro"
              :disabled="disabledForm[index].intro"
              :init="{
                height: 200,
                menubar: false,
                plugins:
                  'advlist autolink lists link image charmap print preview anchor',
                toolbar: 'formatselect | bold italic | bullist numlist',
              }"
            />
            <!-- <b-form-textarea
              v-model="showSitters[index].intro"
              rows="4"
              max-rows="6"
              :disabled="disabledForm[index].intro"
            ></b-form-textarea> -->
          </b-form-group>
        </div>

        <div class="col-6 mb-3 align-items-end">
          <b-form-group label="Skills & Experience">
            <Editor
              api-key="seu906tl28cai7l7fvsn6bsi51ybqeof5m94ed3scxdflh4e"
              v-model="showSitters[index].skill_experience"
              :disabled="disabledForm[index].skill_experience"
              :init="{
                height: 200,
                menubar: false,
                plugins:
                  'advlist autolink lists link image charmap print preview anchor',
                toolbar: 'bullist | numlist',
              }"
            />
            <!-- <b-form-textarea
              v-model="showSitters[index].skill_experience"
              rows="4"
              max-rows="6"
              :disabled="disabledForm[index].skill_experience"
            ></b-form-textarea> -->
          </b-form-group>
        </div>

        <div class="col-12 mb-3 d-flex align-items-end text-end">
          <b-button @click="deleteSitters(item.id)" class="delete btn ms-auto">
            Delete
          </b-button>
          <b-button @click="updateSitters(item.id)" class="update ms-2">
            Update
          </b-button>
        </div>

        <div>
          <hr v-if="index !== showSitters.length - 1" class="my-5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import Loader from '@/landing/components/Loader.vue'
import { openModal } from '@kolirt/vue-modal'
import DefaultModal from '@/admin/components/DefaultModal.vue'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'SittersPage',
  components: {
    Loader,
    Editor,
  },
  data() {
    return {
      isLoading: true,
      cityOptions: [
        'Jakarta',
        'Tangerang',
        'Depok',
        'Bekasi',
        'Bogor',
        'Bandung',
        'Surabaya',
      ],
      serviceOptions: ['Show All Service', 'sitting', 'boarding', 'all'],
      levelOptions: ['Junior', 'Medior', 'Senior'],
      ratingOptions: [1, 2, 3, 4, 5],
      sitters: [],
      showSitters: [],
      disabledForm: [],
      formSearch: {
        name: '',
        service: 'Show All Service',
      },
    }
  },
  created() {
    this.getSitters()
  },
  methods: {
    handleFilterData() {
      this.$nextTick(() => {
        const filteredData = this.sitters.filter(
          (item) =>
            (this.formSearch.name === ''
              ? true
              : item.name
                  .toLowerCase()
                  .includes(this.formSearch.name.toLowerCase())) &&
            (this.formSearch.service === 'Show All Service'
              ? true
              : item.service === this.formSearch.service),
        )
        this.showSitters = filteredData
        this.handleDisabledForm(this.showSitters, true)
      })
    },
    handleOpenModalCreate() {
      openModal(DefaultModal, {
        title: 'Create Sitter',
        form: [
          {
            label: 'Name',
            type: 'text',
          },
          {
            label: 'City',
            type: 'text',
            options: this.cityOptions,
          },
          {
            label: 'Service',
            type: 'text',
            options: this.serviceOptions.filter(
              (item) => item !== 'Show All Service',
            ),
          },
          {
            label: 'Level',
            type: 'text',
            options: this.levelOptions,
          },
          {
            label: 'Year',
            type: 'number',
          },
          {
            label: 'Rating',
            type: 'text',
            options: this.ratingOptions,
          },
          {
            label: 'Image',
            type: 'text',
          },
          {
            label: 'Instagram',
            type: 'text',
          },
          {
            label: 'Introduction',
            type: 'textarea',
          },
          {
            label: 'Skills & Experience',
            type: 'textarea',
          },
        ],
        options: [
          this.cityOptions,
          this.serviceOptions,
          this.levelOptions,
          this.ratingOptions,
        ],
      })
        .then((data) => {
          this.createSitters(data)
        })
        .catch(() => {
          return
        })
    },
    async getSitters() {
      this.isLoading = true
      await api
        .get('sitters')
        .then((response) => {
          this.sitters = response.data
          this.showSitters = response.data
          this.handleDisabledForm(this.sitters, true)
          this.isLoading = false
        })
        .catch((error) => {
          console.error(error)
          toast('Failed to fetch data', {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async updateSitters(id) {
      const index = this.disabledForm.findIndex((item) => item.id === id)
      let updatedItem = this.disabledForm[index]
      if (updatedItem.name) {
        updatedItem = {
          id,
          name: false,
          city: false,
          service: false,
          level: false,
          year: false,
          rating: false,
          img: false,
          ig: false,
          intro: false,
          skill_experience: false,
        }

        return (this.disabledForm[index] = updatedItem)
      }

      if (
        this.showSitters[index].name === '' ||
        this.showSitters[index].city === '' ||
        this.showSitters[index].service === '' ||
        this.showSitters[index].level === '' ||
        this.showSitters[index].year === '' ||
        this.showSitters[index].rating === '' ||
        this.showSitters[index].img === '' ||
        this.showSitters[index].ig === '' ||
        this.showSitters[index].intro === '' ||
        this.showSitters[index].skill_experience === ''
      ) {
        return toast('Please fill all fields', {
          position: 'top-right',
          type: 'error',
          autoClose: 3000,
        })
      }

      this.isLoading = true

      await api
        .patch('sitters-update', {
          id,
          name: this.showSitters[index].name,
          city: this.showSitters[index].city,
          service: this.showSitters[index].service,
          level: this.showSitters[index].level,
          year: this.showSitters[index].year,
          rating: this.showSitters[index].rating,
          img: this.showSitters[index].img,
          ig: this.showSitters[index].ig,
          intro: this.showSitters[index].intro,
          skill_experience: this.showSitters[index].skill_experience,
        })
        .then((response) => {
          this.sitters = response.data.data
          this.showSitters = response.data.data
          this.handleDisabledForm(this.showSitters, true)
          this.handleFilterData()

          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          return (this.isLoading = false)
        })
    },
    async deleteSitters(id) {
      if (!confirm('Are you sure you want to delete this item?')) {
        return
      }

      this.isLoading = true
      await api
        .delete('sitters-delete', {
          data: {
            id,
          },
        })
        .then((response) => {
          this.sitters = response.data.data
          this.showSitters = response.data.data
          this.handleDisabledForm(this.showSitters, true)
          this.handleFilterData()

          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async createSitters(data) {
      const formData = {
        name: data[0].value,
        city: data[1].value,
        service: data[2].value,
        level: data[3].value,
        year: data[4].value,
        rating: data[5].value,
        img: data[6].value,
        ig: data[7].value,
        intro: data[8].value,
        skill_experience: data[9].value,
      }

      if (
        formData.name === '' ||
        formData.city === '' ||
        formData.service === '' ||
        formData.level === '' ||
        formData.year === '' ||
        formData.rating === '' ||
        formData.img === '' ||
        formData.ig === '' ||
        formData.intro === '' ||
        formData.skill_experience === ''
      ) {
        return toast('Please fill all fields', {
          position: 'top-right',
          type: 'error',
          autoClose: 3000,
        })
      }

      this.isLoading = true
      await api
        .post('sitters-create', formData)
        .then((response) => {
          this.sitters = response.data.data
          this.showSitters = response.data.data
          this.handleDisabledForm(this.showSitters, true)
          this.handleFilterData()

          toast(response.data.message, {
            position: 'top-right',
            type: 'success',
            autoClose: 1500,
          })
        })
        .catch((error) => {
          toast(error.response.data.message, {
            position: 'top-right',
            type: 'error',
            autoClose: 3000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleDisabledForm(data, toggle) {
      const disabledItems = data.map((item) => {
        const disabledItem = {}

        for (const key in item) {
          if (key !== 'section_type') {
            if (key === 'id') {
              disabledItem[key] = item[key]
            } else {
              disabledItem[key] = toggle
            }
          }
        }

        return disabledItem
      })

      this.disabledForm = disabledItems
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top {
  border-top-left-radius: 3vh;
  border-top-right-radius: 3vh;
  border-bottom: 1px solid #e6e6e6;
  padding: 5vh 2.5vh 0 2.5vh;
  background: #fff;
  height: 15vh;
}

.top .title {
  font-size: 24px;
  font-weight: bold;
  color: #111111;
}

.top .btn,
.modal .btn.save {
  height: max-content;
  background: #28a745;
  border: 1px solid #28a745;
  color: #fff;
}

.top .btn:hover,
.top .btn:active,
.top .btn:active,
.modal .btn.save:hover,
.modal .btn.save:active,
.modal .btn.save:active {
  background: #28a745;
  border-color: #28a745;
  box-shadow: 0 8px 25px -8px #28a745;
}

.content {
  padding: 2.5vh;
  background: #f5f5f5;
  border-bottom-left-radius: 3vh;
  border-bottom-right-radius: 3vh;
  height: 80vh;
  overflow-y: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.content .title {
  font-size: 20px;
  font-weight: bold;
  color: #111111;
}

.content fieldset {
  margin-bottom: 0 !important;
}

.content .update.btn {
  height: max-content;
  background: #ff5a2a;
  border: 1px solid #ff5a2a;
}

.content .update.btn:hover,
.content .update.btn:active,
.content .update.btn:active {
  background: #ff5a2a;
  border-color: #ff5a2a;
  box-shadow: 0 8px 25px -8px #ff5a2a;
}

.content .delete.btn {
  height: max-content;
  background: #dc3545;
  border: 1px solid #dc3545;
}

.content .delete.btn:hover,
.content .delete.btn:active,
.content .delete.btn:active {
  background: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 8px 25px -8px #dc3545;
}

@media (min-width: 1440px) {
  .top {
    padding: 5vh 15vh 0 15vh;
  }

  .content {
    padding: 5vh 15vh;
  }
}
</style>
