<template>
  <ModalTarget />
  <router-view />
</template>

<script>
import { watchEffect } from 'vue'
import { useRoute } from 'vue-router'

export default {
  setup() {
    const route = useRoute()

    watchEffect(() => {
      document.title = route.meta.title || 'Cat Sitter Indonesia'
    })
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.home {
  margin-top: 5.2vw;
  background: #f8eee2;
}

.home nav {
  background: #ff5a2a;
  transition: 0 !important;
}

.home nav img {
  max-width: 17vw;
  margin-right: 5.83vw;
  cursor: pointer;
}

.home nav .navbar-toggler {
  border: 0;
  padding-right: 0;
}

.home nav .navbar-toggler:active {
  box-shadow: unset !important;
}

.home nav .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>');
}

.home nav .navbar-toggler-icon {
  transition: 0.5s;
  background-image: url('data:image/svg+xml;utf8,<svg class="ast-mobile-svg ast-close-svg" fill="white" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path></svg>');
}

.home nav > .container-fluid {
  padding: 0.73vw 10vw;
}

.home nav .nav-link {
  font-size: 1.25vw;
  color: #fff;
  margin-right: 2.6vw;
}

.home nav .nav-link:hover,
.home nav .nav-link:active {
  color: #fdb23f;
}

.home nav.sticky .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="rgb(255, 90, 42)" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>');
}

.home nav.sticky .navbar-toggler-icon {
  transition: 0.5s;
  background-image: url('data:image/svg+xml;utf8,<svg class="ast-mobile-svg ast-close-svg" fill="rgb(255, 90, 42)" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path></svg>');
}

.home nav.sticky .nav-link {
  color: #ff5a2a;
}

.home nav.sticky .nav-link:hover,
.home nav.sticky .nav-link:active {
  color: #fdb23f;
}

.home .carousel-control-next,
.home .carousel-control-prev {
  opacity: 1 !important;
  width: unset !important;
}

.home .form-check.form-switch {
  align-content: center;
  padding-left: 0;
  display: flex;
  align-items: center;
}

.home .lang .form-check.form-switch input {
  width: 2.5vw;
  height: 1.5vw;
  margin-top: 0;
}

.home .filter .service .form-check.form-switch input {
  width: 3.54vw;
  height: 1.82vw;
  margin-top: 0;
}

.home .form-check.form-switch input {
  background-color: #dadadac4;
  --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ff5a2a%27/%3e%3c/svg%3e');
}

.home .form-check.form-switch input:checked {
  background-color: #dadadac4;
  border-color: transparent;
  --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ff5a2a%27/%3e%3c/svg%3e');
}

.home .form-check.form-switch input:active {
  box-shadow: none;
  border-color: transparent;
  --bs-form-switch-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ff5a2a%27/%3e%3c/svg%3e');
}

.home .form-check.form-switch input:hover {
  cursor: pointer;
}

.vue-modal-body {
  overflow-y: auto;
  max-height: 60vh;
}

@media (max-width: 991.98px) {
  .home {
    margin-top: 13vw;
  }

  .home nav img {
    width: auto;
    max-width: 50vw;
  }

  body:has(.navbar-collapse.show) .home nav {
    height: 100vh;
  }

  body:has(.navbar-collapse.show) .home nav .container-fluid {
    position: absolute;
    top: 1.97vw;
  }

  .home nav .navbar-collapse {
    margin-top: 12vw;
  }

  .home nav > .container-fluid {
    padding: 0.73vw 5vw;
  }

  .home nav .nav-link {
    font-size: 4vw;
    text-align: center;
    padding-bottom: 50vw;
    transition: 0.8s;
    color: #ff5a2a;
  }

  .home nav.sticky .nav-link {
    color: #fff;
  }

  .home nav .navbar-collapse.show .nav-link {
    padding-bottom: 9vw;
    color: #fff;
  }

  .home nav.sticky .navbar-collapse.show .nav-link {
    color: #ff5a2a;
  }

  .home nav .navbar-collapse.show .nav-link:hover,
  .home nav .navbar-collapse.show .nav-link:active {
    color: #fdb23f;
  }

  .home .lang .form-check.form-switch input {
    width: 10vw;
    height: 6vw;
  }

  .home .filter .service .form-check.form-switch input {
    width: 15vw;
    height: 7.7vw;
  }
}
</style>
